@charset "UTF-8";
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
.nahledy {
  margin: 0;
  padding: 0;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
body {
  color: black;
  background: white;
  font-size: 12pt;
  font-family: serif;
}

a {
  color: black;
  text-decoration: none;
}

#lista {
  padding: 0;
  display: none;
}

#napiste, #obalmenu1, #obalobrazek, #obrazek, .noprint, .volba, form, #vlajky, #jazyky, #hlobsah, #listovani, #textovelistovani, .zpet, #kalakci, #kalendar, #mbannery, #titul .odkazy, #jmeniny, .nastaveni, h3.zobr {
  display: none;
}

.skryt, #pata a, .dalsi, #ReakceDokument, .inline, #logo, .seznam th img, #menu, #nadmenu, .dprohlizecka, #vlevo a.cist, #pomocne, #linkynakotvy, #hlava a, .fkont, .abo, #abo {
  display: none;
}

.rozbalit, .zobrazit, .vyhlodkaz, .stranka a, #bannery, #webkamera, #fv, h2, hr, .zobrazeno a, .zobrazeno h3, .nadpissekce, .cesta, #pocitadlo, #anketa img, #anketa .hlas, .prohlizecka {
  display: none;
}

#epoch_popup_calendar, .voladmin, .administrace, #menu-start--trigger, .soc-sites, .gte-hledani, .grecaptcha-badge {
  display: none !important;
}

.editor h2, #stred h2, #vpravo h2, h2.print, #pocasi, #pocasi h2 {
  display: block;
}

table {
  border: 2px gray solid;
  border-collapse: collapse;
  empty-cells: show;
}

td, th {
  page-break-inside: avoid;
  padding: 2pt;
  border: 1px silver solid;
  border-width: 1px 1px 0 0;
}

li {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

p, ul, .kontex2 {
  margin: 5pt 0;
  padding: 0;
}

dt {
  font-weight: bold;
}

ul.ui li, .dok li {
  clear: both;
  margin-top: 5pt;
}

#anketa li {
  display: block;
  list-style-type: none;
}

#anketa dt,
#anketa dd,
#anketa li * {
  display: inline;
}

#anketa dt,
#anketa .odpoved {
  float: left;
  clear: both;
  padding-right: 0.5cm;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  page-break-after: avoid;
  margin: 0;
  padding: 0.5cm 0 0 0;
}

h1.cvi {
  font-size: 16pt;
}

img {
  border: 0;
}

.ui img {
  float: left;
  margin-bottom: 5pt;
  margin-right: 5pt;
}

.sf {
  clear: both;
  height: 0.01cm;
  overflow: hidden;
  font-size: 1%;
  line-height: 1%;
}

.nahledy li {
  display: inline;
  list-style-type: none;
  width: 24.5%;
  text-align: center;
  margin: 0;
  padding: 5px 0;
  float: left;
  display: block;
}

.nahledy li.prvni {
  clear: both !important;
  margin-left: 0;
}

.nahledy div {
  border: 2px silver solid;
  width: 88%;
  padding: 5px 0;
  overflow: hidden;
}

.nahledy div div {
  border: 0;
  width: auto;
  text-align: center;
  margin: 5px;
  padding: 5px;
}

.nahledy img {
  float: none;
  margin: 5px 10px;
}

.nahledy span.popisek {
  font-size: 10pt;
}

.nahledy p.ktg,
.nahledy .gpn {
  display: none;
}

#pata {
  width: 100%;
  clear: both;
  border-top: 1px black solid;
  margin-top: 1cm;
}

#pata a.patalogo {
  display: inline;
}

.kontext2 a {
  display: inline;
}

/* Kvůli tisku prázdných stránek v IE */
html {
  height: auto !important;
}

body {
  min-height: 0 !important;
  position: static !important;
}

body [id*="popupID"],
body #DocumentBlockingSpread,
body > img {
  display: none !important;
}

html,
body,
#obalpata {
  page-break-after: avoid;
}
